<template>
  <div class="download">
    <h3>当前讲座录音下载</h3>
    <p v-for="(file, key) in files" :key="key"><a :href="file.path" :download="file.name" target="_blank">{{file.name}}</a></p>
    <!-- <DownloadFiles /> -->
    <h3>往期语聊录音下载地址</h3>
    <!--<p><a href="/downloads/刘俊辉.mp3" download="刘俊辉.mp3">mp3歌曲</a></p> -->
    <p><a href="https://pan.baidu.com/s/1Vcpao2HBhbgur8MeCVTBgw" target="_blank">《庄子外篇》</a> 提取码:3ch9</p>
    <p> <a href="https://pan.baidu.com/s/1nwhSdEL" target="_blank">《西游记》</a> </p>
    <p> <a href="https://pan.baidu.com/s/1eRBnCgq" target="_blank">《悟真篇》</a> </p>
    <p><a href="https://pan.baidu.com/s/1ZMcxISP917hGBUC1oW9k-g" target="_blank">《坛经》</a> 提取码:c9az</p>
    <p><a href="https://pan.baidu.com/s/1F52EfMwOpWCQjzXvswqrsA" target="_blank">《庄子》</a> 提取码:ahtl</p>
    <p> <a href="https://pan.baidu.com/s/1BOGzuvvKzVx04-OBe1XjXw" target="_blank">《黄庭外景经》</a> 提取码:t9dx</p>
    <p> <a href="https://pan.baidu.com/s/1i7epjAd" target="_blank">《黄庭内景经》</a></p>
    <p><a href="https://pan.baidu.com/s/1dFYbzQL" target="_blank">《阴符经》</a> </p>
    <p><a href="https://pan.baidu.com/s/1kWYf21x" target="_blank">《道德经》</a> </p>
    <p><a href="https://pan.baidu.com/s/1gho2BZ1" target="_blank">草庄团拜会</a> </p>
    <p><a href="https://pan.baidu.com/s/1nxGB_wCP7y17cprGDYXiAA" target="_blank">2009年录音</a> 提取码:1234</p>
  </div >
</template>

<script>
import axios from 'axios'
// import { reactive } from 'vue'

export default {
  data () {
    return { files: [] }
  },
  beforeMount () {
    axios.get('/xuande_api/files')
      .then((response) => {
        this.$data.files = response.data
        console.log(this.$data.files)
      })
  },
  setup () {
    // const name = 'lee'

    // return { files }
  }
}
</script>

<style lang='scss' scoped>
.download {
  display: flex;
  flex-direction: column;
  // height: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  font-size: .18rem;
  padding-top: 1.6rem;
  padding-bottom: .45rem;
  h3 {
    color: #42b983;
    font-size: .3rem;
    font-weight: 400;
    line-height: 1.45;
  }
  p {
    margin: .1rem 0;
  }
  a {
    text-decoration: none;
    color: #2c3e50;
  }
  a:hover {
    text-decoration: underline;
  }
}

</style>
