<template>
    <h3>互动交流平台</h3>
    <div class="interaction">
      <div class="inter_platform">
        <h4><span class="iconfont icon">&#xe637;</span>微信群</h4>
        <p>瞻彼阙聊天室</p>
      </div>
      <div class="inter_platform">
        <h4><span class="iconfont icon">&#xe667;</span>QQ群</h4>
        <p>12185286</p>
      </div>
      <div class="inter_platform">
        <h4><span class="iconfont icon">&#xe650;</span>微信公众号</h4>
        <p>YWCZH_MIN_000</p>
      </div>
      <div class="inter_platform">
        <h4><span class="iconfont icon">&#xe620;</span>YY语聊频道</h4>
        <p>道隐无名 （ID:20159376）</p>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Platform',
  mounted () {

  },
  setup () {
  }
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: .21rem;
  font-weight: 600;
  line-height: 1.45;
}
.interaction {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: stretch;
  justify-content: space-between;
  // padding: .1rem .4rem;
  .inter_platform {
    flex-grow: 1;
    flex-basis: 23%;
    max-width: 23%;
    h4 {
      color: #42b983;
      font-weight: 400;
    }
    .icon {
      font-size: .2rem;
    }
  }
}
@media screen and (max-width: 600px) {
  .interaction {
    flex-direction: column;
    .inter_platform {
      max-width: 100%;
      width: 100%;
    }
    .inter_platform::before {
      content: "—";
      color: #42b983;
    }
  }
}
</style>
