<template>
  <div>
    管理页面
  </div>
</template>

<script>
// // import {toRefs} from 'vue'

// export default {
//   setup () {
//     // const name = 'lee'
//     return { name: 'dell' }
//   }
// }
</script>

<style lang='scss' scoped>
.about {
  padding: .25rem .4rem .45rem;
  .section-about {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: stretch;
    h3 {
      color: #42b983;
      font-weight: 400;
      font-size: .3rem;
      text-align: center;
    }
    .item {
      flex-grow: 1;
      flex-basis: 45%;
      max-width: 45%;
      line-height: 1.6;
    }
  }
  .section-line {
    padding: .25rem .4rem .45rem;
  }
}
.traffic {
  padding: .25rem .4rem .45rem;
  h3 {
    text-align: center;
    font-size: .21rem;
    font-weight: 600;
  }
  .section-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    align-content: stretch;
    .item {
      flex-grow: 1;
      flex-basis: 40%;
      max-width: 40%;
      font-size: .16rem;
      line-height: 1.25;
      .plan {
        font-weight: 600;
      }
    }
  }
}
@media screen and (max-width: 600px) {
.about {
  .section-about {
    flex-direction: column;
    .item {
      max-width: 100%;
      width: 100%;
    }
  }
}
.traffic {
  .section-line {
    flex-direction: column;
    .item {
      max-width: 100%;
      width: 100%;
    }
  }
}
}
</style>
