<template>
  <div>
  <div class="about">
    <section class="section-about">
      <div class="item">
        <h3>云雾草庄简介</h3>
        <p>（云雾草庄）坐落在湖北省麻城市宋埠镇喻河村的熊仙湾，这是一个实践《道德经》理念、验证道德修为、探讨道德精神的场所，也是每一位真心想改造自己、发展自己的实践之地。</p>
      </div>
      <div class="item">
        <h3>联系我们</h3>
        <p>地址: 湖北省麻城市宋埠镇喻河村熊仙湾 云雾草庄</p>
        <p>固定电话: (+86) 0713-2069532</p>
        <p>手机: 18995721259</p>
        <p>联系人: 熊老师</p>
        <p><strong>去草庄前请先电话联系!</strong></p>
      </div>
    </section>
  </div >
  <div class="traffic">
    <h3>乘车路线</h3>
    <section class="section-line">
      <div class="item">
        <p><span class="plan">方案一:</span>武昌火车站下车，出站右转100米即有“宏基长途汽车客运站”。或坐公交车518路到“付家坡长途汽运站”，两站路。两客运站每小时均有一班开往“麻城”方向的汽车，买票到“宋埠镇”，在宋埠沙桥站下车，然后坐“摩的”到草庄（又名熊仙武馆）。</p>
      </div>
      <div class="item">
        <p><span class="plan">方案二:</span>汉口火车站下车，出站左广场乘公交车509路到新荣村客运站，买“宋埠”车票即可。以下同方案一。</p>
      </div>
      <div class="item">
        <p><span class="plan">方案三:</span>武汉高铁站下车，出站后在站前广场右边乘公交车634路到新荣村客运站，买“宋埠”车票即可。以下同上述。</p>
      </div>
      <div class="item">
        <p><span class="plan">方案四:</span>麻城火车站下车，出站后坐公交车，在106国道路口转乘到“宋埠镇”的中巴车在宋埠镇“沙桥”站下。 以下同上述。</p>
      </div>
    </section>
  </div>
  </div>
</template>

<script>
// // import {toRefs} from 'vue'

// export default {
//   setup () {
//     // const name = 'lee'
//     return { name: 'dell' }
//   }
// }
</script>

<style lang='scss' scoped>
.about {
  padding: .25rem .4rem .45rem;
  .section-about {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: stretch;
    h3 {
      color: #42b983;
      font-weight: 400;
      font-size: .3rem;
      text-align: center;
    }
    .item {
      flex-grow: 1;
      flex-basis: 45%;
      max-width: 45%;
      line-height: 1.6;
    }
  }
  .section-line {
    padding: .25rem .4rem .45rem;
  }
}
.traffic {
  padding: .25rem .4rem .45rem;
  h3 {
    text-align: center;
    font-size: .21rem;
    font-weight: 600;
  }
  .section-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    align-content: stretch;
    .item {
      flex-grow: 1;
      flex-basis: 40%;
      max-width: 40%;
      font-size: .16rem;
      line-height: 1.25;
      .plan {
        font-weight: 600;
      }
    }
  }
}
@media screen and (max-width: 600px) {
.about {
  .section-about {
    flex-direction: column;
    .item {
      max-width: 100%;
      width: 100%;
    }
  }
}
.traffic {
  .section-line {
    flex-direction: column;
    .item {
      max-width: 100%;
      width: 100%;
    }
  }
}
}
</style>
