<template>
    <div class="concept">
      <h3>玄德理念</h3>
    <ul class="concept-list">
      <li class="item" v-for="(item, index) in dataList" :key="index">{{item}}</li>
    </ul>
    </div>
</template>

<script>
export default {
  name: 'Concept',
  setup () {
    const dataList = ['先道后德，先阴后阳，先虚后实，先苦后乐，先人后己',
      '先修佛性，后进仙道',
      '要修就要性命双修',
      '无心求正,有意归真',
      '情为道根,魔为真种',
      '融入共性,保留个性',
      '认识自己,改造自己,发展自己',
      '不确定法则,同一性法则,集约性法则',
      '师不为师、自性为师、众人为师、万物为师',
      '尊师悟道灵性通']
    return { dataList }
  }
}
</script>

<style lang="scss" scoped>
.concept {
  display: inline-flex;
  flex-direction: column;
  place-content: center;
}
h3 {
  font-size: .21rem;
  font-weight: 600;
  line-height: 1.45;
  text-align: center;
}

</style>
