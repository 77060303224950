<template>
  <div class="scriptures">
    <p>内容维护更新中...</p>
  </div >
</template>

<script>
// // import {toRefs} from 'vue'

// export default {
//   setup () {
//     // const name = 'lee'
//     return { name: 'dell' }
//   }
// }
</script>

<style lang='scss' scoped>
.scriptures {
  display: flex;
  // height: 100%;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  font-size: .3rem;
  padding-top: 1.6rem;
}

</style>
